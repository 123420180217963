<template>
  <div>
    {{ dataForm }}
  </div>
</template>
<script>

export default {
  props: {
    dataForm: {
      type: String,
      default: null,
    },
  },
}
</script>
