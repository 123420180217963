var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('action-btn',{attrs:{"selected-number":_vm.selectedNumber},on:{"add-item":_vm.addCommom,"delete-item":_vm.deleteCommom}}),_c('good-table',{ref:"vgTable",staticClass:"mt-1 fixed-column",attrs:{"columns":_vm.columns,"rows":_vm.rows,"total":_vm.total,"is-loading":_vm.isLoading},on:{"column-filter":_vm.columnFilter,"selected-item":_vm.selectedItem,"page-change":_vm.pageChange,"update-item":_vm.updateCommom,"delete-item":_vm.deleteCommomTable},scopedSlots:_vm._u([{key:"custom-filter",fn:function(ref){
var props = ref.props;
return _c('div',{},[(props.column.field == 'tenNhomNguoiDung')?_c('span',[_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenNhomNguoiDung",staticClass:"vtreeselect-chooser",attrs:{"id":"linhVuc","default-options":_vm.nndCbx,"placeholder":"Chọn tên vai trò","limit":0,"multiple":"","limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizer,"match-keys":['label', 'label2'],"load-options":_vm.onChange,"async":true,"clear-on-select":true},on:{"select":function($event){return _vm.$refs.tenNhomNguoiDung.resetSearchQuery()},"input":function($event){return _vm.columnFilterTable()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.payload.nhomNguoiDungId),callback:function ($$v) {_vm.$set(_vm.payload, "nhomNguoiDungId", $$v)},expression:"payload.nhomNguoiDungId"}})],1):(props.column.field == 'tenTTHC')?_c('span',[_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenLinhVuc",staticClass:"vtreeselect-chooser",attrs:{"id":"linhVuc","default-options":_vm.tthcCbx,"placeholder":"Chọn tên thủ tục hành chính","limit":0,"multiple":"","limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizer,"match-keys":['label', 'label2'],"load-options":_vm.onQueryChange,"async":true,"clear-on-select":true},on:{"select":function($event){return _vm.$refs.tenLinhVuc.resetSearchQuery()},"input":function($event){return _vm.columnFilterTable()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.payload.thuTucHanhChinhId),callback:function ($$v) {_vm.$set(_vm.payload, "thuTucHanhChinhId", $$v)},expression:"payload.thuTucHanhChinhId"}})],1):_vm._e()])}}])}),_c('common-modal',{ref:"commonModal",attrs:{"title":_vm.title,"size":_vm.size},on:{"handle-ok":_vm.handleOk}},[_c(_vm.componentName === 'XoaForm' ? '' : _vm.componentName,{tag:"component",attrs:{"form-mode":_vm.formModeDialog,"data-form":_vm.dataForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }