<template>
  <div>
    <div v-if="formMode === 'Add'">
      <b-form-group
        id="input-group-1"
        label="Tên vai trò"
        label-for="input-1"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenVaiTro"
          :rules="{ required: true }"
          name="Tên vai trò"
        >

          <v-select
            ref="autoSelect"
            v-model="dataForm.nhomNguoiDungId"
            class="style-chooser"
            :options="npCbx"
            placeholder="Chọn tên vai trò"
            :reduce="np => np.id"
            label="name"
            :filter="onFilterSelect"
            :multiple="true"
          >
            <span slot="no-options">
              Không có dữ liệu !
            </span>
          </v-select>

          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
    </div>
    <div v-else-if="formMode === 'Edit'">
      <b-form-group
        id="input-group-1"
        label="Tên vai trò"
        label-for="input-1"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenVaiTro"
          :rules="{ required: true }"
          name="Tên vai trò"
        >

          <v-select
            v-model="dataForm.nhomNguoiDungId"
            :options="nndCbx"
            placeholder="Chọn tên vai trò"
            class="style-chooser"
            :reduce="np => np.id"
            label="name"
            :filter="onFilterSelect"
            :disabled="true"
            :multiple="true"
          >
            <span slot="no-options">
              Không có dữ liệu !
            </span>
          </v-select>

          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
    </div>
    <b-form-group
      id="input-group-1"
      label="Tên thủ tục hành chính"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenThuTucHanhChinh"
        :rules="{ required: true }"
        name="Tên thủ tục hành chính"
      >
        <v-select
          v-model="dataForm.thuTucHanhChinhId"
          :options="tthcCbx"
          class="style-chooser"
          placeholder="Chọn tên thủ tục hành chính"
          :reduce="np => np.id"
          :multiple="true"
          :filter="onFilterSelect"
          label="name"
        >
          <span slot="no-options">
            Không có dữ liệu !
          </span>
        </v-select>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormGroup,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { removeDiacritical } from '@/utils/index'
// import $ from 'jquery'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormGroup,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
    formMode: { type: String, default: 'Add' },
  },
  data() {
    return {
      tthcCbx: [],
      npCbx: [],
      nndCbx: [],
    }
  },
  mounted() {
    this.getDataThuTucHanhChinhCbx()
    this.getDataNhomNguoiDungChuaVaiTro()
    this.getDataNhomNguoiDung()
    // eslint-disable-next-line func-names
    // this.$nextTick(function () {
    //   const input = this.$refs.autoSelect.$el.querySelector('input')
    //   input.focus()
    // $('.vs__search').focus()
    // eslint-disable-next-line no-unused-expressions
    // })
  },
  methods: {
    getDataThuTucHanhChinhCbx() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.tthcCbx = res.data.data
        }
      })
    },
    getDataNhomNguoiDungChuaVaiTro() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.DS_VAI_TRO_CHUA_CAU_HINH).then(res => {
        if (res.data?.succeeded) {
          this.npCbx = res.data.data
        }
      })
    },
    getDataNhomNguoiDung() {
      this.$axios.get(END_POINTS.CHUNG.NHOM_NGUOI_DUNG).then(res => {
        if (res.data?.succeeded) {
          this.nndCbx = res.data.data
        }
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .vs__selected {
          background-color:#005BD4 !important;
}
::v-deep .vs__deselect:disabled svg.feather.feather-x{
  display: none !important;
}
</style>
