<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      @add-item="addCommom"
      @delete-item="deleteCommom"
    />
    <good-table
      ref="vgTable"
      class="mt-1 fixed-column"
      :columns="columns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
      @update-item="updateCommom"
      @delete-item="deleteCommomTable"
    >
      <div
        slot="custom-filter"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'tenNhomNguoiDung'">
          <treeselect
            id="linhVuc"
            ref="tenNhomNguoiDung"
            v-model="payload.nhomNguoiDungId"
            v-format-v-select
            :default-options="nndCbx"
            placeholder="Chọn tên vai trò"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            class="vtreeselect-chooser"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            :load-options="onChange"
            :async="true"
            :clear-on-select="true"
            @select="$refs.tenNhomNguoiDung.resetSearchQuery()"
            @input="columnFilterTable()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>
        <span v-else-if="props.column.field == 'tenTTHC'">
          <treeselect
            id="linhVuc"
            ref="tenLinhVuc"
            v-model="payload.thuTucHanhChinhId"
            v-format-v-select
            :default-options="tthcCbx"
            placeholder="Chọn tên thủ tục hành chính"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            class="vtreeselect-chooser"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            :load-options="onQueryChange"
            :async="true"
            :clear-on-select="true"
            @select="$refs.tenLinhVuc.resetSearchQuery()"
            @input="columnFilterTable()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>

      </div>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        :form-mode="formModeDialog"
        :data-form="dataForm"
      />
    </common-modal>
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import { removeDiacritical } from '@/utils/index'
import END_POINTS from '@/api/endpoints'
import ThuTucHanhChinhNNDForm from '@/modules/danh-muc/components/form/ThuTucHanhChinhNNDForm.vue'
import XoaForm from '@/modules/danh-muc/components/form/XoaForm.vue'
import _debounce from 'lodash/debounce'

export default {
  components: {
    BCard,
    ActionBtn,
    CommonModal,
    GoodTable,
    ThuTucHanhChinhNNDForm,
    XoaForm,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới phân quyền thủ tục hành chính - vai trò',
      formModeDialog: 'Add',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên vai trò',
          field: 'tenNhomNguoiDung',
          thClass: 'text-center',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenTTHC',
          thClass: 'text-center',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '80px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      rows: [],
      selectedItems: [],
      tthcCbx: [],
      nndCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        nhomNguoiDungId: [],
        thuTucHanhChinhId: [],
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        nhomNguoiDungId: null,
        thuTucHanhChinhId: null,
      },
      isLoading: false,
      size: null,
    }
  },
  created() {
    this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
    this.getDataThuTucHanhChinh()
    this.getDataNhomNguoiDungID()
  },
  methods: {
    addCommom() {
      this.formModeDialog = 'Add'
      this.title = 'Thêm mới phân quyền thủ tục hành chính - vai trò'
      this.componentName = 'ThuTucHanhChinhNNDForm'
      this.size = 'lg'
      this.dataForm = {
        thuTucHanhChinhId: null,
        nhomNguoiDungId: null,
      }
      this.$refs.commonModal.showModal()
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.nndCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQuery(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQuery: _debounce(async function (query, callback) {
      const response = this.tthcCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    getDataThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.tthcCbx = res.data.data
        }
      })
    },
    getDataNhomNguoiDungID() {
      this.$axios.get(END_POINTS.CHUNG.NHOM_NGUOI_DUNG).then(res => {
        if (res.data?.succeeded) {
          this.nndCbx = res.data.data
        }
      })
    },
    getDataPhanQuyenThuTucHanhChinhNhomNguoiDung() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          data: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
            this.$refs.commonModal.hideModal()
          }
        })
      } else if (this.componentName === 'ThuTucHanhChinhNNDForm') {
        this.formModeDialog = 'Add'
        // eslint-disable-next-line prefer-const
        let para = []
        this.dataForm.thuTucHanhChinhId.forEach(item => {
          if (Array.isArray(this.dataForm.nhomNguoiDungId)) {
            para.push(...this.dataForm.nhomNguoiDungId.map(ele => ({
              thuTucHanhChinhId: item,
              nhomNguoiDungId: ele,
            })))
          } else {
            para.push({
              thuTucHanhChinhId: item,
              nhomNguoiDungId: this.dataForm.nhomNguoiDungId,
            })
          }
        })
        if (this.dataForm?.id) {
          this.formModeDialog = 'Edit'
          this.$axios.put(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.SUA, para).then(res => {
            if (res.data?.succeeded) {
              this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
              this.$refs.commonModal.hideModal()
            }
          })
        } else {
          this.formModeDialog = 'Add'
          this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.THEM, para).then(res => {
            if (res.data?.succeeded) {
              this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
              this.$refs.commonModal.hideModal()
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.formModeDialog = 'Edit'
      this.title = 'Cập nhật phân quyền thủ tục hành chính - vai trò'
      this.componentName = 'ThuTucHanhChinhNNDForm'
      this.size = 'lg'
      const param = {
        thuTucHanhChinhId: [],
        nhomNguoiDungId: [{ id: data.nhomNguoiDungId }],
        pageNumber: 1,
        pageSize: this.total,
      }
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.DS, param, false).then(res => {
        if (res.data?.succeeded) {
          this.dataForm = {
            id: data.id,
            thuTucHanhChinhId: res.data?.data.map(item => item.thuTucHanhChinhId),
            nhomNguoiDungId: data.nhomNguoiDungId,
          }
          this.$refs.commonModal.showModal()
        }
      })
    },
    deleteCommom() {
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa phân quyền thủ tục hành chính - vai trò?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    deleteCommomTable(data) {
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa phân quyền thủ tục hành chính - vai trò?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      const payload = {
        ...data,
        thuTucHanhChinhId: this.payload.thuTucHanhChinhId.length > 0 ? this.payload.thuTucHanhChinhId.map(item => ({ id: item })) : [],
        nhomNguoiDungId: this.payload.nhomNguoiDungId.length > 0 ? this.payload.nhomNguoiDungId.map(item => ({ id: item })) : [],
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.DS, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
  },
}
</script>
