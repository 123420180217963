<template>
  <phan-quyen-t-t-h-c-nhom-nguoi-dung />
</template>
<script>
import PhanQuyenTTHCNhomNguoiDung from '@/modules/danh-muc/components/pages/danh-muc/thu-tuc-hanh-chinh/PhanQuyenTTHCNhomNguoiDung.vue'

export default {
  components: {
    PhanQuyenTTHCNhomNguoiDung,
  },
}
</script>
